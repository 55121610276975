export default {
  api: {
    basePath: `${process.env.REACT_APP_REMOTE_SERVER}v1`,
  },
  registers: {
    route: '/admin/users/account',
  },
  customers: {
    route: '/admin/customers',
  },
  collections: {
    route: '/debt-collection-action',
  },
  loan: {
    route: '/admin/shop-loan/credit-line',
  },
  loan_request: {
    route: '/admin/shop-loan/credit-line-request',
  },
  advisor: {
    route: '/admin/sales-advisor',
  },
  shop: {
    route: 'admin/shops',
  },
  headquarter: {
    route: '/admin/wholesaler/headquarter',
  },
  wholesaler: {
    route: '/admin/wholesalers',
  },
  zip_code_detail: {
    route: '/admin/zip-codes',
  },

};
