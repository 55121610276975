/* eslint-disable no-mixed-operators */
/* eslint-disable react/no-children-prop */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  CircularProgress, FormControl, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';
import FileCopy from '@material-ui/icons/FileCopy';
import SnackBar from '@material-ui/core/Snackbar';
import { v4 as uuidv4 } from 'uuid';
import Box from '../../components/Layout/Box';
import PageContainer from '../../components/PageContainer';
import SectionTitle from '../../components/SectionTitle';
import MissingDataAlert from '../../components/MissingDataAlert/MissingDataAlert';
import useStyles from './WareHouse.styles';
import { WareHouseTable } from './WareHouseTable';
import { searchWareHouse} from './WareHouse.actions';
import Text from '../../components/Text';
import CustomModal from '../../components/CustomModal/CustomModal';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import { createWholesalerUser } from '../Wholesalers/wholesalerCreateActions';
import I18n from '../../utils/i18n';
import Alert from "@material-ui/lab/Alert";

const WareHouse = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);
  const [inputValue, setInputVale] = useState('');
  const [queryingShops, setQueryingShops] = useState(false);
  const [showQRModal, setShowQRModal] = useState({
    open: false,
    tradeName: '',
    qrPath: '',
  });
  const [isCopyClipboard, setIsCopyClipboard] = useState(false);
  const [dataWareHouse, setDataWareHouse] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [showAddUsermodal, setShowAddUserModal] = useState(false);
  const [newuserRole, setNewUserRole] = useState('');
  const [newUserPhone, setNewUserPhone] = useState('');
  const [alert, setAlert] = React.useState({
    open: false,
    message: '',
    severity: '',
  });
  const i18n = I18n(process.env.REACT_APP_COUNTRY);


  const handleNewUserPhoneChange = (event) => {
    const { value } = event.target;
    setNewUserPhone(value);
  };

  const {
    LOADING,
    ERROR,
    getWareHouseData,
  } = useSelector((state) => state.wareHouseReducer);

  const {
    isCreatingWholesalerUser,
    wasCreatedWholesalerUser,
    error,
  } = useSelector((state) => state.wholesalerReducer);

  useEffect(() => {
    if (error) {
      setAlert({
        ...alert,
        open: true,
        message: error,
        severity: 'error',
      });
    } else {
      setAlert({
        ...alert,
        open: false,
        message: '',
      });
    }
  }, [error]);

  const closeAlert = () => {
    setAlert({ ...alert, open: false });
  };


  useEffect(() => {
    if (wasCreatedWholesalerUser) {
      setAlert({
        ...alert,
        open: true,
        message: 'Usuario creado exitosamente',
        severity: 'success',
      });
      setShowAddUserModal(false);
      setNewUserPhone('');
      setNewUserRole('');
    }
  }, [wasCreatedWholesalerUser]);

  useEffect(() => {
    setQueryingShops(LOADING.getWareHouse);
  }, [LOADING]);

  useEffect(() => {
    if (ERROR.getWareHouse) {
      showNothingFoundNotice(true);
      setInputVale('');
    }
  }, [ERROR]);

  useEffect(() => {
    if (Object.keys(getWareHouseData).length !== 0) {
      setDataWareHouse(getWareHouseData);
    }
  }, [getWareHouseData]);

  useEffect(() => {
    if (nothingFoundNoticeShown) {
      setTimeout(() => showNothingFoundNotice(false), 3000);
    }
  }, [nothingFoundNoticeShown]);

  const _searchWareHouse = () => {
    dispatch(searchWareHouse(inputValue));
  };


  const _createWholesalerUser = () => {
    if (selectedWarehouse) {
      const { account_id, headquarter } = selectedWarehouse;
      const headquarterId = headquarter.id || '';
      const wholesalerId = account_id || '';
      dispatch(createWholesalerUser(`${i18n.lada}${newUserPhone}`, account_id, headquarterId, wholesalerId, newuserRole));
    } else {
      console.error('No hay warehouse seleccionado');
    }
  };

  const _showQR = (warehouse) => {
    setShowQRModal((prevState) => ({
      ...prevState,
      open: true,
      tradeName: warehouse.trade_name,
      qrPath: warehouse.qr_path,
    }));
  };

  const handleChangeNewUserRole = (event) => {
    setNewUserRole(event.target.value);
  };

  const handleCloseModalQR = () => {
    setShowQRModal((prevState) => ({
      ...prevState,
      open: false,
      tradeName: '',
      qrPath: '',
    }));
  };

  return (
    <PageContainer>
      <Box className={classes.rootContainer}>
        <Box
          className={classes.topContainer}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <SectionTitle>Sucursales de Bodegas</SectionTitle>
          { nothingFoundNoticeShown && (
          <MissingDataAlert status="BAD" text="No se encontraron sucursales" />
          )}
          <Box style={{ display: queryingShops ? 'block' : 'none' }}>
            <CircularProgress />
          </Box>
          <Box className={classes.actionsContainer}>
            <Box component="form" className={classes.searchContainer}>
              <InputBase
                value={inputValue}
                onChange={(event) => {
                  setInputVale(event.target.value);
                }}
                className={classes.input}
                placeholder="Buscar por nombre, ID, nombre del dueño..."
                inputProps={{ 'aria-label': 'search user' }}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    _searchWareHouse();
                  }
                }}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                onClick={() => {
                  _searchWareHouse();
                }}
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box className={classes.tableContainer}>
          <WareHouseTable resources={dataWareHouse} _showQR={_showQR} _showAddUser={setShowAddUserModal} _setSelectedWarehouse={setSelectedWarehouse} />
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showQRModal.open}
        onClose={handleCloseModalQR}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showQRModal.open}>
          <Box className={classes.modalContainer}>
            <div className={classes.modalTextContainer}>
              <Text className={classes.textTitleModalStyle}>
                {`Codigo QR - ${showQRModal.tradeName || '-'}`}
              </Text>
              <div
                onClick={() => handleCloseModalQR()}
              >
                <CloseIcon />
              </div>
            </div>
            <div className={classes.modalTextContainer} style={{ marginTop: 20 }}>
              <Text className={classes.textModalStyle}>
                {showQRModal.qrPath || '-'}
              </Text>
              <div
                className={classes.subMenuImages}
              >
                <div
                  className={classes.imagesSubMenuContainer}
                  onClick={() => {
                    navigator.clipboard.writeText(showQRModal.qrPath);
                    setIsCopyClipboard(true);
                  }}
                >
                  <FileCopy
                    style={{ color: 'white' }}
                  />
                </div>
              </div>
            </div>
            <Box style={{
              display: 'flex', marginTop: 20, alignItems: 'center', justifyContent: 'center',
            }}
            >
              <img
                style={{ width: '70%', height: '70%' }}
                src={showQRModal.qrPath}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
      <SnackBar
        open={isCopyClipboard}
        autoHideDuration={3000}
        onClose={() => {
          setIsCopyClipboard(false);
        }}
        message="Link de QR Copiado"
      />
      <CustomModal
        isMessage={false}
        title="Agregar usuario"
        open={showAddUsermodal}
        setOpen={setShowAddUserModal}
        closeButton
      >
        <FormControl style={{ marginLeft: 50, width: 150 }}>
          <Box style={{ marginTop: 12, marginBottom: 15 }}>
            <TextInput
              label="Télefono"
              variant="standard"
              value={newUserPhone}
              onChange={handleNewUserPhoneChange}
              helperText={
                        newUserPhone > 0 && newUserPhone.length < 10
                      }
              inputProps={{ maxLength: 10 }}
            />
          </Box>
          <FormControl style={{ width: 150 }}>
            <InputLabel>Rol:</InputLabel>

            <Select
              value={newuserRole}
              onChange={handleChangeNewUserRole}
            >
              <MenuItem value="HQ">Usuario Global</MenuItem>
              <MenuItem value="BO">Usuario de Sucursal/ Ruta</MenuItem>
            </Select>
          </FormControl>
          <Box style={{ marginTop: 12, display: 'flex', height: 30 }}>
            <Button
              variant="contained"
              color="secondary"
              children="Agregar"
              onClick={() => {
                _createWholesalerUser();
              }}
              isDisabled={newUserPhone > 0 && newUserPhone.length < 10 || newuserRole === '' || isCreatingWholesalerUser}
            />
          </Box>
        </FormControl>
      </CustomModal>
       <SnackBar
              open={alert.open}
              onClose={closeAlert}
              autoHideDuration={10000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert severity={alert.severity} onClose={closeAlert}>
                {alert.message}
              </Alert>
            </SnackBar>
    </PageContainer>
  );
};

export default WareHouse;
