export const FETCH_ZIP_CODE_INIT = 'WHOLESALER::FETCH_ZIP_CODE_INIT';
export const FETCH_ZIP_CODE_SUCCESS = 'WHOLESALER::FETCH_ZIP_CODE_SUCCESS';
export const FETCH_ZIP_CODE_ERROR = 'WHOLESALER::FETCH_ZIP_CODE_ERROR';
export const CREATE_HEADQUARTER_INIT = 'WHOLESALER::CREATE_HEADQUARTER_INIT';
export const CREATE_HEADQUARTER_SUCCESS = 'WHOLESALER::CREATE_HEADQUARTER_SUCCESS';
export const CREATE_HEADQUARTER_ERROR = 'WHOLESALER::CREATE_HEADQUARTER_ERROR';
export const CREATE_WHOLESALER_INIT = 'WHOLESALER::CREATE_WHOLESALER_INIT';
export const CREATE_WHOLESALER_SUCCESS = 'WHOLESALER::CREATE_WHOLESALER_SUCCESS';
export const CREATE_WHOLESALER_ERROR = 'WHOLESALER::CREATE_WHOLESALER_ERROR';
export const FIND_HEADQUARTER_INIT = 'WHOLESALER::FIND_HEADQUARTER_INIT';
export const FIND_HEADQUARTER_SUCCESS = 'WHOLESALER::FIND_HEADQUARTER_SUCCESS';
export const FIND_HEADQUARTER_ERROR = 'WHOLESALER::FIND_HEADQUARTER_ERROR';
export const CLEAR_WHOLESALER_STATE = 'WHOLESALER::CLEAR_WHOLESALER_STATE';
export const CREATE_WHOLESALER_USER_INIT = 'WHOLESALER::CREATE_WHOLESALER_USER_INIT';
export const CREATE_WHOLESALER_USER_SUCCESS = 'WHOLESALER::CREATE_WHOLESALER_USER_SUCCESS';
export const CREATE_WHOLESALER_USER_ERROR = 'WHOLESALER::CREATE_WHOLESALER_USER_ERROR';
