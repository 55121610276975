import * as ACTIONS from '../containers/Wholesalers/actionTypes';

const initialState = {
  isFetchingZcd: false,
  zcdList: [],
  isFetchingHq: false,
  hqList: [],
  isCreatingHeadquarter: false,
  wasCreatedHeadquarter: false,
  isCreatingWholesaler: false,
  wasCreatedWholesaler: false,
  isCreatingWholesalerUser: false,
  wasCreatedWholesalerUser: false,
  error: '',
};

const wholesalerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_ZIP_CODE_INIT:
      return { ...state, isFetchingZcd: true };
    case ACTIONS.FETCH_ZIP_CODE_SUCCESS:
      return {
        ...state, isFetchingZcd: false, error: null, zcdList: action.value,
      };
    case ACTIONS.FETCH_ZIP_CODE_ERROR:
      return { ...state, isFetchingZcd: false, error: action.error };
    case ACTIONS.CREATE_WHOLESALER_INIT:
      return { ...state, isCreatingWholesaler: true, wasCreatedWholesaler: false };
    case ACTIONS.CREATE_WHOLESALER_SUCCESS:
      return { ...state, isCreatingWholesaler: false, wasCreatedWholesaler: true };
    case ACTIONS.CREATE_WHOLESALER_ERROR:
      return {
        ...state,
        isCreatingWholesaler: false,
        wasCreatedWholesaler: false,
        error: action.error,
      };
    case ACTIONS.CREATE_HEADQUARTER_INIT:
      return { ...state, isCreatingHeadquarter: true, wasCreatedHeadquarter: false };
    case ACTIONS.CREATE_HEADQUARTER_SUCCESS:
      return { ...state, isCreatingHeadquarter: false, wasCreatedHeadquarter: true };
    case ACTIONS.CREATE_HEADQUARTER_ERROR:
      return {
        ...state,
        isCreatingHeadquarter: false,
        wasCreatedHeadquarter: false,
        error: action.error,
      };
    case ACTIONS.FIND_HEADQUARTER_INIT:
      return { ...state, isFetchingHq: true };
    case ACTIONS.FIND_HEADQUARTER_SUCCESS:
      return {
        ...state, isFetchingHq: false, error: null, hqList: action.value,
      };
    case ACTIONS.FIND_HEADQUARTER_ERROR:
      return { ...state, isFetchingHq: false, error: action.error };
    case ACTIONS.CLEAR_WHOLESALER_STATE:
      return { ...initialState };
    default:
      return { ...state };
    case ACTIONS.CREATE_WHOLESALER_USER_INIT:
      return { ...state, isCreatingWholesalerUser: true, wasCreatedWholesalerUser: false };
    case ACTIONS.CREATE_WHOLESALER_USER_SUCCESS:
      return { ...state, isCreatingWholesalerUser: false, wasCreatedWholesalerUser: true };
    case ACTIONS.CREATE_WHOLESALER_USER_ERROR:
      return {
        ...state,
        isCreatingWholesalerUser: false,
        wasCreatedWholesalerUser: false,
        error: action.error,
      };
  }
};

export default wholesalerReducer;
